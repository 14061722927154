import { map, take, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpService } from 'services/http/http.service';
import { OrgUserProfile } from 'interfaces/profile.interface';
import { ListRequestParams } from 'utils/interfaces';
import { buildQueryParams, createQueryParam, getRedirectByRoleId, sortItemByName } from 'utils/helpers';
import { Observable, of } from 'rxjs';
import { Profile } from 'services/profile/profile.interface';
import { Store } from '@ngrx/store';
import { Selectors } from 'features/profiles-state/+state/profiles-state.state';
import { ShowIfHttpError } from '@certemy/common';
import { RegistrationFormFieldToSave } from 'components/registration-form/edit/registration-form.component';
import { UserCertificationsData } from 'features/user-profile/organization/organization-user-interface';
import { UserProfile } from 'services/user/user.interface';
import { ROLE_CODES } from 'constants/role.constants';
import { WORKFLOW_MODULE_ID } from 'constants/workflow.constants';
import { START_URL_SETTINGS_POLICIES, startUrlPermissionsType } from 'constants/auth.constants';
import { PolicyPermissionsService } from 'services/policy-permission/policy-permissions.service';
import { SmartForm } from '@certemy/ui-components';
import { RowsResponse } from 'interfaces/common.interface';
import {
  RelatedCertification,
} from 'features/user-profile/shared/certification-archive-modal/certification-archive-modal.constants';

@Injectable()
export class ProfileService {
  private _profile: UserProfile = null;
  private _profiles: UserProfile[] = [];
  private _startUrlPermissions: startUrlPermissionsType;

  get profile() {
    return this._profile;
  }

  get profiles() {
    return this._profiles;
  }

  constructor(
    private http: HttpService,
    private store: Store<any>,
    private policyPermissionsService: PolicyPermissionsService,
  ) {
    this.store.select(Selectors.profilesState).subscribe(({ availableProfiles, currentProfile }) => {
      this._profiles = availableProfiles;
      this._profile = currentProfile;
    });
  }

  @ShowIfHttpError('Cannot receive user profile.')
  getProfile(profileId: number): Observable<OrgUserProfile> {
    return this.http.get<OrgUserProfile>(`/profile/${profileId}`);
  }

  @ShowIfHttpError('Cannot receive user profile.')
  getProfileForOtherOrganization(profileId: number, queryParams: any): Observable<OrgUserProfile> {
    const params = [createQueryParam('organizationId', queryParams.organizationId)];
    const queryParamsString = buildQueryParams(params);
    return this.http.get<OrgUserProfile>(`/profile/certemy-tasks/${profileId}${queryParamsString}`);
  }

  @ShowIfHttpError('Could not login at this profile.')
  loginAsProfile(profileId: number) {
    return this.http.get(`/auth/login-as/profile/${profileId}`);
  }

  @ShowIfHttpError('An error occurred while switching profile.')
  switchProfile(profileId: number) {
    return this.http.put(`/profile/${profileId}/switch`, null).pipe(map(res => res.token));
  }

  @ShowIfHttpError('An error occurred while update profile group(s).')
  updateGroups(profileId: number, updatedGroups: Profile.ProfileGroups[]) {
    return this.http.put(`/profile/${profileId}/groups`, updatedGroups);
  }

  @ShowIfHttpError('An error occurred while loading credentials.')
  getProfileCertificates(
    profileId,
    { search = null, order_id = null, order_type = 'asc', filters = null, page = 1, page_size = 10 }: ListRequestParams,
  ): Observable<UserCertificationsData> {
    const queryParams = buildQueryParams([
      createQueryParam('search', search),
      createQueryParam('filters', filters),
      createQueryParam('page', page),
      createQueryParam('page_size', page_size),
      createQueryParam('order_id', order_id),
      createQueryParam('order_type', order_type),
    ]);
    return this.http
      .get(`/profile/${profileId}/certifications${queryParams}`)
      .pipe(map(data => ({ count: data.count, certs: data.result })));
  }

  getProfileCertificate(profileId: number, certificateId: number): Observable<Profile.CertificateInfo> {
    return this.http.get(`/profile/${profileId}/certifications/${certificateId}`);
  }

  @ShowIfHttpError('An error occurred while updating registration form.')
  updateRegistrationForm(body: { certificationInstanceId: number; fields: RegistrationFormFieldToSave[] | boolean }) {
    return this.http.put('/registration-form-instance', body);
  }

  getProfileCertificatePhases(
    profileId: number,
    certificateId: number,
    params: ListRequestParams,
  ): Observable<{ result: Profile.CertificatePhase[]; count: number; full_count: number }> {
    params = {
      order_id: 'name',
      order_type: 'asc',
      page: 1,
      page_size: 10,
      ...params,
    };
    const queryParams = buildQueryParams([
      createQueryParam('page', params.page),
      createQueryParam('page_size', params.page_size),
      createQueryParam('order_id', params.order_id),
      createQueryParam('order_type', params.order_type),
    ]);
    return this.http.get(`/profile/${profileId}/certifications/${certificateId}/phases${queryParams}`);
  }

  @ShowIfHttpError('An error occurred while updating status for dashboard. Please, try again.')
  putProfileCertificateStatus(
    profileId: number,
    certificateId: number,
    data: { use_status: boolean; status: string },
  ): Observable<any> {
    return this.http.put(`/profile/${profileId}/certifications/${certificateId}/status`, data);
  }

  @ShowIfHttpError('Cannot receive phase steps.')
  getStepsByCertificationPhase({ profileId, certificateId, phaseId }): Observable<Profile.CertificatePhaseStepsData> {
    const url = `/profile/${profileId}/certifications/${certificateId}/phases/${phaseId}/steps`;
    return this.http.get<{ result: Profile.CertificatePhaseStepsData }>(url);
  }

  @ShowIfHttpError('Credential deletion failed.')
  deleteUserCertification(certificationInstanceId: number) {
    return this.http.delete(`/certification-instance/${certificationInstanceId}`);
  }

  @ShowIfHttpError('Cannot receive related certifications.')
  getRelatedCertifications(certificationInstanceId: number): Observable<RelatedCertification[]> {
    return this.http.get(`/certification-instance/${certificationInstanceId}/related-certifications`);
  }

  @ShowIfHttpError('Failed to archive credentials.')
  archiveUserCertification(certificationInstanceIds: number[]): Observable<Profile.Certificate> {
    return this.http.post(`/certification-instance/archive`, { certificationInstanceIds });
  }

  @ShowIfHttpError('Failed to unpause credentials.')
  unpauseUserCertification(certificationInstanceIds: number[]): Observable<Profile.Certificate> {
    return this.http.post(`/certification-instance/unpause`, { certificationInstanceIds });
  }

  @ShowIfHttpError('An error occurred while getting notifications.')
  getEmailNotifications(profileId: number) {
    return this.http.get(`/profile-mail-settings/${profileId}`);
  }

  @ShowIfHttpError('An error occurred while updating notifications.')
  updateEmailNotifications(profileId: number, changedNotification) {
    return this.http.put(`/profile-mail-settings/${profileId}`, changedNotification);
  }

  /* set permissions for start and logo urls */
  setStartUrlPermissions(role_id: ROLE_CODES): Observable<any> {
    this._startUrlPermissions = null;

    if (role_id !== ROLE_CODES.ORGANIZATION_ADMIN) {
      return of(true);
    }

    const url_policies = Object.values(START_URL_SETTINGS_POLICIES);
    return this.policyPermissionsService.hasMultiplePermissions(url_policies).pipe(
      take(1),
      tap((permissions: startUrlPermissionsType) => {
        this._startUrlPermissions = permissions;
      }),
    );
  }

  getStartUrlByRolePermission(profile?: UserProfile): string {
    profile = profile || this.profile;
    const { role_id } = profile;

    if (role_id !== ROLE_CODES.ORGANIZATION_ADMIN || !this._startUrlPermissions) {
      return getRedirectByRoleId(role_id);
    }

    let isTestingModuleAvailableInProfile = false;
    if (profile.modules) {
      const { testingWorkflow, sharedTestingWorkflow } = profile.modules;
      isTestingModuleAvailableInProfile = testingWorkflow || sharedTestingWorkflow;
    }

    let {
      credentialing,
      credentialingDashboard,
      credentialingVerificationList,
      credentialingPrimaryVerification,
      testing,
      testingWorkflowDashboard,
      testingWorkflowVerificationList,
      testingWorkflowPrimaryVerification,
      testingWorkflowTodoVerification,
      compliance,
      complianceWorkflowDashboard,
      complianceWorkflowVerificationList,
      complianceWorkflowPrimaryVerification,
      complianceWorkflowTodoVerification,
    } = this._startUrlPermissions;
    credentialingDashboard = credentialing && credentialingDashboard;
    credentialingVerificationList = credentialing && credentialingVerificationList && credentialingPrimaryVerification;
    testing = isTestingModuleAvailableInProfile && testing;
    testingWorkflowDashboard = testing && testingWorkflowDashboard;
    testingWorkflowVerificationList = testing && testingWorkflowVerificationList && testingWorkflowPrimaryVerification;
    testingWorkflowTodoVerification = testing && testingWorkflowTodoVerification;
    complianceWorkflowDashboard = compliance && complianceWorkflowDashboard;
    complianceWorkflowVerificationList =
      compliance && complianceWorkflowVerificationList && complianceWorkflowPrimaryVerification;
    complianceWorkflowTodoVerification = compliance && complianceWorkflowTodoVerification;

    // ALL GROUPS
    if (credentialingDashboard) {
      // Credentials dashboard
      return '/organization/dashboard';
    } else if (complianceWorkflowDashboard) {
      // Compliance dashboard
      return `/organization/module/${WORKFLOW_MODULE_ID.COMPLIANCE}/compliance-dashboard`;
    } else if (testingWorkflowDashboard) {
      // Testing dashboard
      return `/organization/module/${WORKFLOW_MODULE_ID.TESTING_WORKFLOW}/testing-dashboards`;
    } else if (credentialingVerificationList) {
      // Credentials verification list
      return '/verifier/jobs';
    } else if (complianceWorkflowVerificationList) {
      // Compliance verification list
      return `/organization/module/${WORKFLOW_MODULE_ID.COMPLIANCE}/verification`;
    } else if (complianceWorkflowTodoVerification) {
      // Compliance to do list
      return `/organization/module/${WORKFLOW_MODULE_ID.COMPLIANCE}/todo`;
    } else if (testingWorkflowVerificationList) {
      // Testing verification list
      return `/organization/module/${WORKFLOW_MODULE_ID.TESTING_WORKFLOW}/verification`;
    } else if (testingWorkflowTodoVerification) {
      // Testing to do list
      return `/organization/module/${WORKFLOW_MODULE_ID.TESTING_WORKFLOW}/todo`;
    } else {
      // Profile page
      return `/organization/profile/${this._profile.profile_id}`;
    }
  }

  @ShowIfHttpError('An error occurred while getting organization admin title options.')
  getOrgAdminTitleOptions(): Observable<SmartForm.FieldOption[]> {
    return this.http
      .get('/profile/titles')
      .pipe(map((response: RowsResponse<SmartForm.FieldOption>) => sortItemByName(response.rows)));
  }
}
